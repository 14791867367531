// Here you can add other styles
.title-value {
    font-size: 1.2rem;
    font-weight: 600;
    font-style: italic;
}

.text-value {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 5px;
    margin-bottom: .75rem;
}

.total-value {
    font-size: 1.1rem;
    font-weight: bold;
    padding-left: 5px;
}

.final-value {
    font-size: 1.5rem;
    font-weight: 600;
    font-style: italic;
}

.logo-img {
    height: 80%;
    margin: 0 auto;
}

.main-bg {
    width: 80%;
    margin: 0 auto;
}

.cafe-logo {
    width: 200px;
    max-height: 150px;
    height: auto;
}

.app-header .navbar-brand {
    width: auto;
    padding-left: 1rem;
}

.max-height1 {
    max-height: 135px;
    min-height: 45px;
}

.sidebar .sidebar-nav {
    overflow: inherit;
}

.sidebar .nav-dropdown.open .nav-link {
    color: #888888;
}

.sidebar .nav-dropdown-items a.nav-link {
    padding-left: 1.5rem;
    color: $gray-800;

    &.active {
        background: inherit;
        color: $blue;

        &:hover {
            color: white;
        }
    }

    &:hover {
        color: white;
    }
}

.spinner {
    margin: 10px auto;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: $sidebar-nav-link-icon-color;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.table td,
.table th {
    vertical-align: middle;
}

.buttonHolder {
    float: right;

    a {
        color: $light;
        background: $sidebar-nav-link-icon-color;
        border: 1px solid $sidebar-nav-link-icon-color;
        border-radius: 0.25rem;
        padding: 0.375rem 1.5rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            background-color: $sidebar-nav-link-icon-color;
            border-color: $sidebar-nav-link-icon-color;
            text-decoration: none;
        }
    }
}

.displayImage {
    width: 200px;
    height: 200px;
}

.mandatory {
    :after {
        content: '*';
        color: red;
    }
}

.hide {
    display: none;
}

select[multiple] option {
    margin: .5rem 0;
}

@media (max-width: 576px) {
    .app-header .navbar-brand {
        position: static;
        margin-right: 1rem;

        img {
            max-height: 60%;
        }
    }

    .d-flex-parent {

        .form-inline,
        .btn-group {
            justify-content: flex-start;
            text-align: left;
            width: 100%;
        }

        .form-group {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    #formGroupPagination {
        margin: 0 8px;
    }

    .thead-th-default,
    .tbody-td-default {
        min-width: 175px
    }
}

.alert:not(.relative) {
    position: fixed;
    z-index: 100;
    right: 30px;
    bottom: 30px;
    padding: 4rem;
}